import { ComponentProps } from 'react'
import { SpaceTokens, styled, theme } from 'stitches.config'

const child = '> *'
const childWithGap = '> * + *'

const gap = Object.keys(theme.space).reduce(
  (acc, cv) => ({
    ...acc,
    [cv]: { [childWithGap]: { $$stackGap: `$space$${cv}` } },
  }),
  { 0: { [childWithGap]: { $$stackGap: 0 } } }
) as { [key in SpaceTokens | '0']: any }

export const Stack = styled('div', {
  display: 'flex',
  listStyleType: 'none',
  paddingLeft: 0,

  [child]: {
    margin: 0,
  },

  [childWithGap]: {
    $$stackGapLeft: 'initial',
    $$stackGapRight: 'initial',
    $$stackGapTop: 'initial',

    marginLeft: '$$stackGapLeft !important',
    marginTop: '$$stackGapTop !important',
    marginRight: '$$stackGapRight !important',
  },

  variants: {
    display: {
      none: {
        display: 'none',
      },
      flex: {
        display: 'flex',
      },
      block: {
        display: 'block',
      },
      inline: {
        display: 'inline',
      },
      'inline-block': {
        display: 'inline-block',
      },
    },
    width: {
      fullWidth: {
        width: '100%',
      },
      fullWidthBp2: {
        width: '100%',
        '@bp2': {
          width: '50%',
        },
      },
    },
    alignItems: {
      'flex-start': {
        alignItems: 'flex-start',
      },
      'flex-end': {
        alignItems: 'flex-end',
      },
      center: {
        alignItems: 'center',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
    justifyContent: {
      'space-between': {
        justifyContent: 'space-between',
      },
      'space-around': {
        justifyContent: 'space-around',
      },
      'space-evenly': {
        justifyContent: 'space-evenly',
      },
      'flex-start': {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      'flex-end': {
        justifyContent: 'flex-end',
      },
    },
    direction: {
      row: {
        flexDirection: 'row',
        [childWithGap]: {
          $$stackGapLeft: '$$stackGap',
          $$stackGapTop: 0,
        },
      },
      column: {
        flexDirection: 'column',
        [childWithGap]: {
          $$stackGapTop: '$$stackGap',
          $$stackGapLeft: 0,
        },
      },
      wrap: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        [childWithGap]: {
          $$stackGapLeft: '$$stackGap', // Adjust left gap for wrap direction
          $$stackGapRight: '$$stackGap', // Adjust top gap for wrap direction
        },
      },
    },
    gap,
    reversed: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
    mobileSingleColumn: {
      true: {},
    },
    itemsPerRow: {
      1: {
        [child]: {
          '&:not(:first-child)': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
          '&:first-child': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
          width: '100%', // Two items per row\
        },
      },
      2: {
        [child]: {
          '&:not(:first-child)': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
          '&:first-child': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
          width: '48%', // Two items per row\
        },
      },
      3: {
        [child]: {
          flexBasis: '31%', // Three items per row
          '&:not(:first-child)': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
          '&:first-child': {
            marginLeft: '1% !important',
            marginTop: '1% !important',
            marginRight: '1% !important',
          },
        },
      },
      default: {
        [child]: {
          width: 'auto',
        },
      },
      // Add more configurations as needed
    },
  },

  compoundVariants: [
    {
      direction: 'row',
      reversed: true,
      css: {
        [childWithGap]: {
          $$stackGapRight: '$$stackGap',
          $$stackGapLeft: 0,
          $$stackGapTop: 0,
        },
      },
    },
    {
      direction: 'column',
      reversed: true,
      css: {
        [childWithGap]: {
          $$stackGapLeft: 0,
          $$stackGapTop: 0,
          $$stackGapBottom: '$$stackGap',
        },
      },
    },
  ],

  defaultVariants: {
    gap: '1',
    direction: 'row',
    reversed: false,
  },
})

export type StackProps = ComponentProps<typeof Stack>
